import { Box, Flex, Link, Text, useColorModeValue } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import AuthBasic from "../../layout/AuthBasic";
import SignUpForm from "./Components/SignUpFormData";

const SignUp = () => {
  const textColor = useColorModeValue("gray.400", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  const titleColor = useColorModeValue("gray.700", "blue.500");

  return (
    <>
      <AuthBasic title="Welcome!" description="Create your account">
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          mb="50px"
          mt="20px"
        >
          <Flex
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="15px"
            p="30px"
            mx={{ base: "100px" }}
            bg={bgForm}
            boxShadow={useColorModeValue(
              "0px 5px 14px rgba(0, 0, 0, 0.05)",
              "unset"
            )}
            zIndex={9}
          >
 <Text  fontSize="small"
            color="red"
              fontWeight="10"
              textAlign="center"
              fontFamily="Oswald">
             We regret to inform you that Notarize Genie Inc. will cease operations. Notaries are advised to download their journals and video recordings within 90 days to ensure compliance with record-keeping requirements from your state.
             <br/> Interested in Acquiring Our Code?
We are selling the platform’s code. For inquiries, please contact admin@notarizegenie.com.
Thank you for your support.
           </Text>



            <Text
              fontSize="xl"
              color={textColor}
              fontWeight="700 "
              fontFamily="Oswald"
              textAlign="center"
            >
              Register
            </Text>
            <Box mt="20px">
              <SignUpForm />
            </Box>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
            >
              <Text color={textColor} fontWeight="400" fontFamily="Lato">
                Already have an account?
                <RouterLink to="/sign-in">
                  <Link
                    color={titleColor}
                    as="span"
                    ms="5px"
                    href="#"
                    fontWeight="bold"
                  >
                    Sign In
                  </Link>
                </RouterLink>
              </Text>
            </Flex>
            <Link
              href="https://www.notarizegenie.com"
              textAlign="center"
              color={textColor}
              fontWeight="400"
              fontFamily="Lato"
              mt={2}
              textDecoration="underline"
            >
              Go to landing page
            </Link>
          </Flex>
        </Flex>
      </AuthBasic>
    </>
  );
};

export default SignUp;

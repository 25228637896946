import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Flex,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import AuthBasic from "../../layout/AuthBasic";
import SignInForm from "./Components/SignInForm";
import { BASE_URL } from "../../utils/constants";

const SignIn = () => {
  const textColor = useColorModeValue("gray.400", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  const titleColor = useColorModeValue("gray.700", "blue.500");
  const [isUnderMaintenance, setUnderMaintenance] = useState<boolean>(false);

  useEffect(() => {
    fetch(`${BASE_URL}/users/maintenance`).then(async (res) => {
      const result = await res.json();
      if (result.value === true) {
        setUnderMaintenance(result.value);
      } else {
        setUnderMaintenance(false);
      }
    });
  }, []);

  return (
    <>
      <AuthBasic title="Welcome Back!">
        <Flex
          w="100%"
          h="100%"
          alignItems="center"
          justifyContent="center"
          mb="60px"
          mt={{ base: "30px", lg: "0px" }}
        >
          <Flex
            zIndex="2"
            direction="column"
            w="445px"
            minW={{ base: "95%", lg: "unset", md: "unset" }}
            background="transparent"
            borderRadius="15px"
            p={{ base: "40px 20px", lg: "40px", md: "40px" }}
            mx={{ base: "100px" }}
            mb={{ base: "20px", md: "auto" }}
            bg={bgForm}
            boxShadow={useColorModeValue(
              "0px 5px 14px rgba(0, 0, 0, 0.05)",
              "unset"
            )}
          >
           <Text  fontSize="12px"
            color="red"
              fontWeight="8"
              textAlign="center"
              fontFamily="Oswald">
             We regret to inform you that Notarize Genie Inc. will cease operations. Notaries are advised to download their journals and video recordings within 90 days to ensure compliance with record-keeping requirements from your state.
             </Text> 
             <Text  fontSize="small"
            color="red"
              fontWeight="8"
              textAlign="center"
              fontFamily="Oswald">
              Interested in Acquiring Our Code?
We are selling the platform’s code. For inquiries, please contact admin@notarizegenie.com.
Thank you for your support.
           </Text>


            <Text
              fontSize="xl"
              fontWeight="700"
              textAlign="center"
              fontFamily="Oswald"
            >
              Sign In
            </Text>

            <Text
              mt={2}
              mb="20px"
              textAlign={"center"}
              color={textColor}
              fontWeight="400"
              fontFamily="Lato"
            >
              Don’t have an account?
              <RouterLink to="/sign-up">
                <Link
                  color={titleColor}
                  as="span"
                  ms="5px"
                  href="#"
                  fontWeight="bold"
                >
                  Sign up
                </Link>
              </RouterLink>
            </Text>

            {isUnderMaintenance && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                <AlertDescription fontSize={"14px"} lineHeight={"5"}>
                  <AlertTitle>Sorry for inconvenience!</AlertTitle>
                  Notarize Genie will be intermittently down as we implement
                  upgrades to our audio video and credential analysis processes.
                  These enhancements ensure we continue to improve the
                  experience for you, our end-user. We apologize for the
                  inconvenience and thank you for your patience and
                  understanding.
                  <br /> Thank you.
                </AlertDescription>
              </Alert>
            )}

            <SignInForm isUnderMaintenance={isUnderMaintenance} />

            {isUnderMaintenance ? (
              ""
            ) : (
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                maxW="100%"
                mt="0px"
              ></Flex>
            )}
            <Link
              href="https://www.notarizegenie.com"
              textAlign="center"
              color={textColor}
              fontWeight="400"
              fontFamily="Lato"
              textDecoration="underline"
            >
              Go to landing page
            </Link>
          </Flex>
        </Flex>
      </AuthBasic>
    </>
  );
};

export default SignIn;

//@ts-nocheck
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useSearchParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import { SignerApi } from "../../../../api";
import { useAuth } from "../../../../utils/use-auth";
import useNotification from "../../../../utils/useNotification";
import { getSessionDetails } from "../../../../utils/utils-apis";
import { ISignerProfileDetails } from "../../../../store/zustandType";
import { useSignerProfileStore } from "../../../../store/signerStore";
import { StateFullnameOptions } from "../../../../utils/options";
import PaymentData from "../../../../component/commonComponents/PaymentCard/PaymentData";

const SignerProfileData = ({
  onClose,
  from,
  onSubmit,
}: {
  onClose: () => void;
  from: string;
  onSubmit: (isNew: boolean) => void;
}) => {
  const { configuration } = useAuth();
  const [searchParams] = useSearchParams();
  const { notification } = useNotification();
  const userId = localStorage.getItem("UserID");
  const sessionId = searchParams.get("session-id");
  const accessToken = searchParams.get("access-token");
  const [called, setCalled] = useState<boolean>(true);
  const [needCard, setNeedCard] = useState<boolean>(true);
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [isDisableSubmit, setIsDisableSubmit] = useState<boolean>(false);
  const { signerProfileDetails, setSignerProfileDetails } =
    useSignerProfileStore();

  const [selectedOption, setSelectedOption] = useState<
    SingleValue<{
      label: string;
      value: string;
    }>
  >(
    StateFullnameOptions.find(
      (item) => item.value === signerProfileDetails.state
    ) || {
      label: "",
      value: "",
    }
  );

  useEffect(() => {
    if (sessionId != null) {
      getSessionDetails(configuration, Number(sessionId)).then((res) => {
        if (
          res?.paymentBy === "signer" &&
          Number(userId) === res?.primarySignerUserId && !res?.isDemoSession
        ) {
          setNeedCard(true);
        } else {
          setNeedCard(false);
        }
      });
    }
  }, [sessionId]);

  useEffect(() => {
    setSelectedOption(
      StateFullnameOptions?.find(
        (item) => item?.value === signerProfileDetails?.state
      ) as SingleValue<{ value: string; label: string }>
    );
  }, [signerProfileDetails]);

  const createSignerProfile = (values: ISignerProfileDetails) => {
    new SignerApi(configuration)
      .signerControllerCreate({
        signerDTO: {
          ...values,
          zip: Number(values.zip),
          // user: Number(UserId),
        },
      })
      .then((res) => {
        notification("success", "Profile Created Successfully");
        setIsDisableSubmit(false);
        setTimeout(() => {
          onSubmit(true);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        setIsDisableSubmit(false);
        notification("fail", "Failed to Create Profile");
      });
  };

  const updateSignerProfile = (values: ISignerProfileDetails) => {
    new SignerApi(configuration)
      .signerControllerUpdateSigner({
        signerUpdateDTO: {
          ...values,
          zip: Number(values.zip),
        },
      })
      .then((res) => {
        notification("success", "Profile Updated Successfully!");
        setIsDisableSubmit(false);
        setTimeout(() => {
          onSubmit(false);
        }, 1000);
      })
      .catch((err) => {
        notification("fail", "Failed to Update Profile");
        setIsDisableSubmit(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      ...signerProfileDetails,
    },

    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(20, "First Name must be 20 characters or less.")
        .required("First Name is required"),
      lastName: Yup.string()
        .max(20, "Last Name must be 20 characters or less.")
        .required("Last Name is required"),
      city: Yup.string()
        .max(50, "City Name must not exceed 50 characters")
        .required("City Name is required"),
      phoneNumber: Yup.string().min(10).required("Phone Number is required"),
      zip: Yup.string()
        .max(8, "Zip Code must be of maximum 8 digits")
        .required("Zip Code is required"),
      addressLine1: Yup.string()
        .max(200, "Address Line 1 must be 200 characters or less.")
        .required("Address Line 1 is required"),
      // dob: Yup.string().required("DOB is required"),
      // lastFourDigitsSsnNumber: Yup.string().required(
      //   "Last Four Digits of SSN Number is required"
      // ),
    }),

    onSubmit: (values) => {
      const dataToSend = {
        ...values,
        phoneNumber: values?.phoneNumber?.replace(/[^\d]/g, ""),
        state: selectedOption?.value as string,
        wantSmsNotification: signerProfileDetails.wantSmsNotification,
      };
      setSignerProfileDetails(dataToSend);
      setIsDisableSubmit(true);
      if (signerProfileDetails?.signerId !== -1) {
        updateSignerProfile(dataToSend);
      } else {
        createSignerProfile(dataToSend);
      }
    },
    enableReinitialize: called,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex flexWrap="wrap">
        <FormControl
          id="firstName"
          w={["100%", "50%", "50%"]}
          minW={"220px"}
          p={2}
        >
          <FormLabel p="1" style={{ fontWeight: "600", fontFamily: "Lato" }}>
            First Name*
          </FormLabel>
          <Input
            {...(formik.touched.firstName && formik.errors.firstName
              ? { isInvalid: true }
              : "")}
            errorBorderColor="red.300"
            name="firstName"
            value={formik.values.firstName}
            type="text"
            placeholder="John"
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            style={{ textTransform: "capitalize" }}
          />
          <Text
            color={"red"}
            fontSize={"14px"}
            fontWeight={"500"}
            marginLeft={"6px"}
          >
            {formik.touched.firstName && formik.errors.firstName
              ? formik.errors.firstName
              : ""}
          </Text>
        </FormControl>

        <FormControl
          id="lastName"
          w={["100%", "50%", "50%"]}
          minW={"220px"}
          p={2}
        >
          <FormLabel p="1" style={{ fontWeight: "600", fontFamily: "Lato" }}>
            Last Name*
          </FormLabel>
          <Input
            name="lastName"
            type="text"
            placeholder="Doe"
            {...(formik.touched.lastName && formik.errors.lastName
              ? { isInvalid: true }
              : "")}
            errorBorderColor="red.300"
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.lastName}
            onBlur={formik.handleBlur}
            style={{ textTransform: "capitalize" }}
          />
          <Text
            color={"red"}
            fontSize={"14px"}
            fontWeight={"500"}
            marginLeft={"6px"}
          >
            {formik.touched.lastName && formik.errors.lastName
              ? formik.errors.lastName
              : ""}
          </Text>
        </FormControl>

        <FormControl id="email" w={["100%", "50%", "50%"]} minW={"220px"} p={2}>
          <FormLabel p="1" style={{ fontWeight: "600", fontFamily: "Lato" }}>
            Email*
          </FormLabel>
          <Input
            required
            name="email"
            value={
              formik.values.email !== ""
                ? formik.values.email
                : localStorage.getItem("email") ?? ""
            }
            type="email"
            pattern="\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b"
            placeholder="Email"
            readOnly={accessToken !== null ? false : true}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
        </FormControl>

        <FormControl
          id="phoneNumber"
          w={["100%", "50%", "50%"]}
          minW={"220px"}
          p={2}
        >
          <FormLabel p="1" style={{ fontWeight: "600", fontFamily: "Lato" }}>
            Phone Number*
          </FormLabel>
          <ReactInputMask
            mask="(999)-999-9999"
            value={formik.values.phoneNumber
              ?.toString()
              ?.replace(
                /(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/,
                "$1$2$3-$4$5$6-$7$8$9$10"
              )}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
          >
            {/* @ts-ignore */}
            {() => <Input required placeholder="(___)-___-____" />}
          </ReactInputMask>

          <Text
            color={"red"}
            fontSize={"14px"}
            fontWeight={"500"}
            marginLeft={"6px"}
          >
            {formik.touched.phoneNumber && formik.errors.phoneNumber
              ? formik.errors.phoneNumber
              : ""}
          </Text>

          <Checkbox
            mt={2}
            fontSize={"12px"}
            isChecked={signerProfileDetails.wantSmsNotification}
            onChange={(e) =>
              setSignerProfileDetails({
                ...signerProfileDetails,
                wantSmsNotification: e.target.checked,
              })
            }
          >
            I would like to receive the SMS reminders.
          </Checkbox>
        </FormControl>

        <FormControl id="addressLine1" p={2}>
          <FormLabel
            fontSize={"14px"}
            p={1}
            style={{ fontWeight: "600", fontFamily: "Lato" }}
          >
            Street Address*
          </FormLabel>
          <Input
            minW={"220px"}
            fontSize={"14px"}
            placeholder="Street Address"
            name="addressLine1"
            type={"text"}
            {...(formik.touched.addressLine1 && formik.errors.addressLine1
              ? { isInvalid: true }
              : "")}
            errorBorderColor="red.300"
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.addressLine1}
            onBlur={formik.handleBlur}
          />
          <Text
            color={"red"}
            fontSize={"14px"}
            fontWeight={"500"}
            marginLeft={"6px"}
          >
            {formik.touched.addressLine1 && formik.errors.addressLine1
              ? formik.errors.addressLine1
              : ""}
          </Text>
        </FormControl>

        <FormControl id="addressLine2" p={2}>
          <FormLabel
            fontSize={"14px"}
            p={1}
            style={{ fontWeight: "600", fontFamily: "Lato" }}
          >
            Address Line
          </FormLabel>
          <Input
            minW={"220px"}
            fontSize={"14px"}
            placeholder="Address Line"
            name="addressLine2"
            type={"text"}
            {...(formik.touched.addressLine2 && formik.errors.addressLine2
              ? { isInvalid: true }
              : "")}
            errorBorderColor="red.300"
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.addressLine2}
            onBlur={formik.handleBlur}
          />
          <Text
            color={"red"}
            fontSize={"14px"}
            fontWeight={"500"}
            marginLeft={"6px"}
          >
            {formik.touched.addressLine2 && formik.errors.addressLine2
              ? formik.errors.addressLine2
              : ""}
          </Text>
        </FormControl>

        <FormControl id="city" w={["100%", "35%", "35%"]} minW={"250px"} p={2}>
          <FormLabel
            fontSize={"14px"}
            p={1}
            style={{ fontWeight: "600", fontFamily: "Lato" }}
          >
            City*
          </FormLabel>
          <Input
            fontSize={"14px"}
            placeholder="eg. Tokyo"
            name="city"
            type={"text"}
            {...(formik.touched.city && formik.errors.city
              ? { isInvalid: true }
              : "")}
            errorBorderColor="red.300"
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.city}
            onBlur={formik.handleBlur}
            style={{ textTransform: "capitalize" }}
          />
          <Text
            color={"red"}
            fontSize={"14px"}
            fontWeight={"500"}
            marginLeft={"6px"}
          >
            {formik.touched.city && formik.errors.city
              ? formik.errors.city
              : ""}
          </Text>
        </FormControl>

        <FormControl id="state" w={["100%", "35%", "35%"]} minW={"250px"} p={2}>
          <FormLabel
            fontSize={"14px"}
            p={1}
            style={{ fontWeight: "600", fontFamily: "Lato" }}
          >
            State*
          </FormLabel>
          <Select
            placeholder="Select State"
            options={StateFullnameOptions}
            value={selectedOption}
            menuPlacement="top"
            onChange={(option) => {
              setCalled(false);
              setSelectedOption(option);
              setSignerProfileDetails({
                ...signerProfileDetails,
                state: option?.value,
              });
            }}
          />
          <Text
            color={"red"}
            fontSize={"14px"}
            fontWeight={"500"}
            marginLeft={"6px"}
          >
            {formik.touched.state && formik.errors.state
              ? formik.errors.state
              : ""}
          </Text>
        </FormControl>

        <FormControl id="zip" w={["100%", "30%", "30%"]} minW={"250px"} p={2}>
          <FormLabel fontSize={"14px"} p={1}>
            Zip*
          </FormLabel>
          <Input
            minLength={5}
            maxLength={5}
            fontSize={"14px"}
            placeholder="Zip"
            name="zip"
            type={"number"}
            {...(formik.touched.zip && formik.errors.zip
              ? { isInvalid: true }
              : "")}
            errorBorderColor="red.300"
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.zip}
            onBlur={formik.handleBlur}
          />
          <Text
            color={"red"}
            fontSize={"14px"}
            fontWeight={"500"}
            marginLeft={"6px"}
          >
            {formik.touched.zip && formik.errors.zip ? formik.errors.zip : ""}
          </Text>
        </FormControl>
      </Flex>
      <Box mt={3} px={3}>
        {sessionId !== null && !needCard && (
          <Text style={{ color: "red", fontSize: "13px" }}>
            Card not required if Business or Notary is paying for your session or for a demo session
          </Text>
        )}

        <PaymentData
          from="profile"
          onDisable={(value) => setIsDisable(value)}
        />
      </Box>

      <Flex justify={"end"} p="2" mt={5}>
        <Button
          type="button"
          width="12%"
          style={{
            background: "#2D3748",
            color: "#fff",
            minWidth: "80px",
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          width="12%"
          ml={4}
          style={{
            minWidth: "80px",
            background:
              "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.52) 100%)",
            // color: "#fff",
          }}
          isDisabled={
            (sessionId === null ? isDisable : needCard ? isDisable : false) ||
            isDisableSubmit
          }
        >
          Save
        </Button>
      </Flex>
    </form>
  );
};

export default SignerProfileData;
